import ru from 'vee-validate/dist/locale/ru.json'
export default (context) => {
  return new Promise(function (resolve) {
    resolve({
      meta: {
        title: 'Билеты на концерты, шоу и другие мероприятия'
      },
      validation: {
        ...ru.messages,
        phone: 'Телефон указан в неверном формате',
        password: 'Пароли не совпадают',
        payment_method: 'Пожалуйста, выберите желаемый способ оплаты'
      },
      errors: {
        basket: {
          ticket_cant_be_reserved: 'Кто-то уже забронировал этот билет'
        },
        checkout: {
          email_invalid: 'Адрес почты введен неверно',
          email_domain_invalid: 'Домен почты не существует',
          surname_too_short: 'Фамилия слишком короткая',
          name_too_short: 'Имя слишком короткое'
        },
        promo_code_not_found: 'Не удалось использовать промокод'
      },
      cookies: {
        content: `Для корректной работы этому сайту требуются файлы cookie. Для детальной информации ознакомьтесь с нашей "политикой использования файлов cookie". Если какие-либо файлы cookie недоступны, то есть вероятность что некоторые сервисы и функции сайта могут не работать. Для лучшего опыта использования и качества обслуживания веб-сайт использует следующие типы файлов cookie: аналитические файлы cookie, которые анализируют и улучшают работу сайта, социальные медиафайлы cookie, которые позволяют вам делиться контентом в социальных сетях, а также маркетинговые файлы cookie.`,
        title: `Если вы хотите продолжить использовать веб-сайт, пожалуйста, отметтье, что вы согласны
         использовать файлы cookie`,
        required: 'Необходимые cookie',
        statistics: 'Аналитические файлы cookie',
        social: 'Файлы cookie социальных сетей',
        marketing: 'Целевые (коммерческие) файлы cookie'
      },
      password: {
        weak: 'Слабый',
        fair: 'Удовлетворительный',
        good: 'Хороший',
        strong: 'Сильный',
        excellent: 'Отличный'
      },
      menuTitles: {
        concerts: 'Концерты',
        theater: 'Театр',
        festivals: 'Фестивали'
      },
      buttons: {
        save: 'Сохранить',
        change_password: 'Сменить пароль',
        sign_in: 'Войти',
        sign_up: 'Зарегистрироваться',
        load_more: 'Загрузить еще',
        retry_purchase: 'Повторить покупку',
        fix_world: 'Попробуй исправить мир',
        float_away: 'Уплыть',
        apply: 'Применить',
        clear: 'Очистить',
        read_more: 'Больше инфо',
        tickets_and_more: 'Билеты и инфо',
        enter: 'Enter'
      },
      modal: {
        cookies_updated: 'Your cookies updated!',
        request_submitted: 'Ваш запрос был успешно отправлен',
        password_changed: 'Пароль изменен!',
        personal_data_updated: 'Персональные данные обновлены!'
      },
      search: {
        search: 'Искать',
        placeholder: 'Поиск концертов, залов',
        view_all_results: 'Показать все',
        no_results_found: 'Ничего не найдено'
      },
      headings: {
        spam: 'Notifications',
        presale_code: 'Предпродажный код...',
        about: 'Информация',
        success: 'Успех!',
        sign_in: 'Войти',
        sign_in_with: 'Войти с помощью',
        sign_up_with: 'Зарегестрироваться с помощью',
        password_change: 'Смена пароля',
        recommended: 'Рекомендованные',
        related: 'Связанные события',
        latest: 'Последние',
        news: 'Новости',
        rescheduled: 'Переносы',
        cancelled: 'Отмены',
        username: 'Имя пользователя',
        old_password: 'Старый пароль',
        new_password: 'Новый пароль',
        repeat_password: 'Повторите пароль',
        password: 'Пароль',
        forgotpass: 'Забыли пароль',
        iagree: 'Я принимаю',
        name: 'Имя',
        surname: 'Фамилия',
        email: 'E-mail',
        phone: 'Телефон',
        or: 'или',
        newuser: 'Новый пользователь',
        userhaveaccount: 'Уже есть аккаунт',
        customersupport: 'Служба поддержки',
        payment: 'Оплата',
        unsubscribe: 'Unsubscribe',
        unsubscribe_subtitile:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        reset_password: 'Смена пароля',
        reset_password_l2:
          'Не беспокойтесь, мы отправим инструкции по смене пароля вам на email',
        categories: 'Категории',
        events: 'События',
        logging_out: 'Выход из системы',
        payment_failed: 'Платеж не проведен',
        search_results_for: 'Результаты поиска для',
        payment_success: 'Ваш платеж получен!'
      },
      badges: {
        soldout: 'Распродано',
        premiere: 'Премьера'
      },
      navigation: {
        information: 'Информация',
        faq: 'ЧаВо',
        terms: 'Условия покупки билетов',
        tickets: 'Билеты',
        etickets: 'Э-билеты',
        payments: 'Платежи',
        privacy: 'Политика конфиденциальности',
        cookies: 'Политика использования cookies',
        contacts: 'Контакты',
        news: 'Новости',
        partners: 'Партнерам',
        userprofile: 'Профиль',
        usertickets: 'Мои билеты',
        about: 'О нас',
        authorize: 'Войти',
        register: 'Регистрация',
        pay: 'Оплата',
        apply: 'Применить',
        logout: 'Выход',
        backToTickets: 'Вернуться к билетам',
        backToEventInfo: 'Вернуться к мероприятию',
        back: 'Back',
        gotopayment: 'К оплате',
        retry_trans: 'Повторить транзакцию',
        closeCart: 'Закрыть корзину',
        remove: 'Удалить',
        insurance: 'Условия страхования',
        publiccontract: 'Общие условия договора купли-продажи билетов'
      },
      labels: {
        company: 'Название компании',
        email: 'Эл. почта',
        confirm_email: 'Подтвердить e-mail',
        legalAddress: 'Юридический адрес',
        name: 'Имя',
        optional: '(необязательно)',
        phone: 'Телефон',
        regnr: 'Рег. но.',
        required: '(требуется)',
        surname: 'Фамилия',
        vat: 'НДС Но.'
      },
      misc: {
        support_email: 'Support email',
        tickets_left: 'Доступно билетов',
        choose_time: 'Выберите время',
        select_your_branch: 'Select Your branch',
        back_to_all_payment_methods: 'Back to all payment methods',
        accept: 'Принять',
        additionalOptions: 'Дополнительные настройки',
        langselector: 'По-русски',
        withuseragreement: 'правила пользования',
        price_from: 'от',
        from: 'с',
        to: 'по',
        price: 'цена',
        soldout: 'распродано',
        discount: 'Скидка',
        download: 'Скачать',
        event_name: 'Название мероприятия',
        loading: 'Загружается',
        processing_order: 'Обрабатываем заказ',
        load_more: 'Загрузить больше',
        more_info: 'Подробнее',
        selectDiscount: 'Выберите скидку',
        sevice_fee_and_vat: 'Плата за обслуживание (с НДС)',
        fixed_service_fee: 'Комиссия площадки (с НДС)',
        ticket_details: 'Информация о билете',
        ticket_id: 'ID билета',
        ticket_no: 'No билета',
        ticket_type: 'Тип билета',
        tickets: 'билетов | билет | билета | билетов',
        total: 'Всего',
        buy_ticket: 'Купить билет',
        add_to_cart: 'Добавить в корзину',
        standticket: 'Стоячие места',
        seatticket: 'Сидячие места',
        close: 'Закрыть',
        required_fields: 'Обязательные поля',
        at: 'в',
        no_tickets: 'Нет билетов',
        order_id: 'ID заказа',
        purchased: 'Куплено',
        download_invoice: 'Скачать счет',
        download_pdf: 'Скачать PDF',
        not_available: 'недоступен',
        work_hours: 'П. - Вс.',
        doors: 'Вход',
        start: 'Начало',
        age_restriction: 'Ограничение по возрасту',
        tags: 'Теги',
        by: '',
        organised_by: 'организовано',
        sector: 'СЕК'
      },
      ticketselector: {
        SALES_NOT_STARTED: {
          title: 'Продажи еще не начались',
          subtitle: ''
        },
        SALES_ENDED: {
          title: 'Продажи завершились',
          subtitle: ''
        },
        AVAILABLE: {
          title: '',
          subtitle: ''
        },
        SOMEONE_IS_BUYING: {
          title: 'Кто-то покупает последние билеты.',
          subtitle:
            'Возможно, через несколько минут некоторые из зарезервированных билетов будут выставлены на продажу'
        },
        OWN: {
          title: '',
          subtitle: ''
        },
        SOLDOUT: {
          title: 'РАСПРОДАНО',
          subtitle: ''
        }
      },
      text: {
        password_reset_email_sent:
          'Инструкции по смене пароля были отправлены на указанный адрес электронной почты',
        password_has_been_changed: 'Ваш пароль был изменен!',
        unsubscribed: 'You successfully unsubscribed!',
        agreeOnTerms: {
          text: 'Я прочитал и согласен с {link}!',
          link: 'условиями использования интернет-магазина и страховки билетов (п.5.7.)'
        },
        cart_is_empty: 'Корзина пуста',
        enter_promo_code: 'Введите промо-код',
        stripeDescription:
          'У вас есть 10 минут, чтобы завершить платеж. В противном случае транзакция будет отменена, а забронированные вами билеты станут вновь доступны для покупки.',
        fdataDescription:
          'У вас есть 10 минут, чтобы завершить платеж. В противном случае транзакция будет отменена, а забронированные вами билеты станут вновь доступны для покупки.',
        i_have_promocode: 'У меня есть промо-код',
        promocode_activated: 'Промокод активирован!',
        promocode_apply_fail: 'Не удалось использовать промокод',
        newsAndOffers:
          'Хотите получать новости и выгодные предложения на билеты?',
        spam: 'Я хочу получать информацию о мероприятиях по электронной почте',
        purchaseAsLegal: 'Купить как юридическое лицо',
        swedDescription:
          '<b>Этот вид оплаты доступен только для клиентов Swedbank в Латвии.</b> У вас есть 10 минут, чтобы завершить платеж. В противном случае транзакция будет отменена, а забронированные вами билеты станут свободно доступны для покупки.',
        payment_failed_w_error: 'Платеж не прошел с кодом ошибки',
        invoice_copy_sent:
          'Копия билета и счета отправлены на вашу электронную почту:',
        invoice_copy_will_be_sent:
          'Билеты скоро будут отправлены на указанный вами электронный адрес.',
        user_exists_1:
          'Пользователь с таким адресом электронной почты уже существует. Пожалуйста, ',
        user_exists_2: 'войдите',
        user_exists_3: ' или ',
        user_exists_4: 'восстановите пароль.',
        something_wrong: 'Что-то пошло не так',
        page_not_found: 'Страница не найдена',
        no_events_available: 'Нет доступных событий',
        order_completed_1: 'Ваш заказ',
        order_completed_2: 'на',
        order_completed_3: 'выполнен',
        processing_tickets: 'Обработка билетов',
        choose_payment_country:
          'Пожалуйста, выберите банки / платежные системы какой страны использовать.'
      },
      titles: {
        agreement: 'Соглашение',
        buyer: 'Покупатель',
        paymentMethod: 'Метод оплаты',
        purchased_tickets: 'Скачать билеты',
        your_tickets: 'Ваши билеты'
      },
      filters: {
        all_dates: 'Все даты',
        all_categories: 'Все категории',
        category_selected: 'категория выбрана',
        pick_category: 'Выбрать категорию',
        all_locations: 'Все места',
        pick_country: 'Пожалуйста, выберите страну',
        location_selected: 'место выбрано',
        pick_location: 'Выбрать место',
        pick_another_date: 'Выбрать другую дату'
      },
      fdata: {
        100: 'Decline (general, no comments)',
        101: 'Decline, expired card',
        102: 'Decline, suspected fraud',
        104: 'Decline, restricted card',
        105: "Decline, card acceptor call acquirer's security department",
        106: 'Decline, allowable PIN tries exceeded',
        111: 'Decline, invalid card number',
        116: 'Decline, no card record',
        118: 'Decline, not sufficient funds',
        119: 'Decline, transaction not permitted to cardholder',
        120: 'Decline, transaction not permitted to terminal',
        121: 'Decline, exceeds withdrawal amount limit',
        122: 'Decline, security violation',
        123: 'Decline, exceeds withdrawal frequency limit',
        129: 'Decline, suspected counterfeit card',
        204: 'Pick-up, restricted card',
        208: 'Pick-up, lost card',
        902: 'Decline reason message: invalid transaction',
        904: 'Decline reason message: format error',
        908: 'Decline reason message: transaction destination cannot be found for routing',
        909: 'Decline reason message: system malfunction',
        911: 'Decline reason message: card issuer timed out',
        912: 'Decline reason message: card issuer unavailable',
        940: 'Decline, blocked by fraud filter'
      },
      eventLabels: {
        postponed: 'Новая дата скоро будет!',
        suspended: 'Продажи этого мероприятияя временно приостановлены.',
        preorder: 'Начало продаж: <b class="color--text-blue wsnw">{from}</b>',
        public_preorder:
          'Начало публичныой предпродажи: <b class="color--text-blue wsnw">{from}</b>'
      },
      eventLabelsButton: {
        preorder: 'Через {0}',
        onlinesaleended: 'Интернет-торговля закрыта'
      },
      presale: {
        early_access: 'Предпродажа раннего доступа',
        early_access_tooltip:
          'Если у вас есть специальный код предпродажи, вы можете купить билеты на это мероприятие до начала открытой продажи. Предпродажные коды распространяются артистом и/или организатором мероприятия. У Ticketshop нет этих кодов для распространения.'
      },
      countdown: {
        days: 'д.',
        hours: 'ч.',
        minutes: 'мин.',
        seconds: 'сек.'
      },
      insurance: {
        available: 'Доступно страхование билетов',
        tickets: 'Страхование билетов',
        title: 'ЗАЩИТИТЕ СЕБЯ И ЗАСТРАХУЙТЕ СВОИ БИЛЕТЫ!',
        description:
          'Возмещение убытков будет осуществлено в случае, если Вы не можете посетить мероприятие по непредвиденным обстоятельствам, по состоянию здоровья, или в случае отмены/переноса мероприятия если новые условия Вас не устраивают.',
        details: 'Подробнее об условиях страхования',
        secure_tickets: 'Застраховать билеты',
        continue_without: 'Продолжить без страховки',
        cost: 'Стоимость страховки',
        secure_order: 'Застраховать покупку',
        cancel: 'Отменить страховку',
        ticket_secured: 'Билет застрахован',
        ticket_unavailable: 'Страхование недоступно',
        provider: 'Страховка "BALCIA"',
        info: {
          title: 'Мы возместим убытки, если:',
          list: [
            'У вас возникли непредвиденные обстоятельства, и Вы не можете попасть на мероприятие;',
            'План посещения мероприятия нарушен Covid19, либо другими проблемами со здоровьем;',
            'Мероприятие отменено, либо перенесено и новое время, или место Вам не подходит.'
          ]
        }
      },
      currencyModal: {
        title: 'У ВАС ЕСТЬ НЕЗАВЕРШЕННАЯ ПОКУПКА В ВАЛЮТЕ {cartCurrency}',
        subtitle:
          'Мероприятие, на которое вы хотите выбрать билеты, в валюте {eventCurrency}. В данный момент в вашей корзине есть билеты, цена которых указана в валюте {cartCurrency}. Вам нужно завершить текущую покупку или очистить корзину, чтобы совершить покупку в валюте {eventCurrency}.',
        finish_payment: 'Завершить текущую покупку в валюте {cartCurrency}',
        clear_cart:
          'Очистить корзину и совершить новую покупку в валюте {eventCurrency}'
      },
      eventSubscribeModal: {
        title: 'Регистрация'
      },
      eventSubscribe: {
        registration: 'Регистрация',
        register: 'Зарегистрироваться',
        earlyBird:
          'Зарегистрируйтесь и получите позможность приобрести эксклюзивные <b class="color--text-blue wsnw">Early bird билеты</b>'
      },
      refund: {
        title: 'ВОЗВРАТ за покупку No. <div>{number}</div>',
        details: 'Информация о возврате',
        deadline: 'Возврат можно запрашивать до:',
        reason: 'Причина возврата',
        info: 'Обратите внимание, что возврат средств осуществляется на тот же банковский счет или платежную карту, с которой была совершена первоначальная покупка.',
        payment: {
          type: 'Способ оплаты',
          bank: 'Название банка',
          payer: 'Имя получателя',
          account: 'Номер счета'
        },
        order_tickets: 'Билеты в покупке',
        ticket_number: 'Номер билета',
        ticket_type: 'Тип билета',
        ticket_price: 'Цена',
        ticket_venue: 'Плата за место проведения',
        ticket_service: 'Плата за обслуживание',
        flat_price: 'Стоимость билетов (вкл. НДС)',
        total_refund: 'Итого к возврату',
        initiate: 'Запросить возврат средств',
        terms: 'Я прочитал и согласен с условиями.',
        steps: {
          initiate: 'Возврат начат',
          process: 'Обработка возврата',
          compete: 'Возврат завершен'
        },
        success: {
          title: 'Запрос на возврат успешно отправлен',
          subtitle:
            'Ваш запрос на возврат будет обработан в ближайшее время. Вы получите отдельное электронное письмо, когда Ваш возврат будет обработан.'
        },
        payout: {
          title: 'Возврат в процессе',
          subtitle:
            'Ваш запрос на возврат будет обработан в ближайшее время. Вы получите отдельное электронное письмо, когда Ваш возврат будет обработан.'
        },
        completed: {
          title: 'Возврат средств завершен',
          subtitle:
            'Ваш возврат оформлен. Подробности см. в электронном письме о возврате.'
        },
        expired: {
          title: 'Срок подачи заявок на возврат истек',
          subtitle:
            'В настоящее время продавец билетов не имеет права выдавать возврат за эту покупку, так как прошло указанное количество дней.'
        }
      },
      ddr: {
        details: 'Детали запроса',
        title: 'Запрос на удаление данных',
        subtitle: '',
        status: 'Статус',
        comment: 'Комментарий',
        created_at: 'Создано',
        updated_at: 'Обновлено'
      }
    })
  })
}
